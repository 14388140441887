<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <BaseDialogFieldBlock title="Активная рассылка" description="">
            <v-select
              v-model="model.action_json.notification_id"
              class=""
              :items="notificationPickList"
              item-text="name"
              item-value="id"
              placeholder="Выберите рассылку"
              outlined
              :rules="[(v) => !!v || 'Выберите рассылку']"
              :loading="getNotifyPickListAction || loading"
            />
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <BaseDialogFieldBlock title="Когда отправить?" description="">
            <v-row align="baseline">
              <v-col cols="auto">
                <v-select v-model="withDelay" class="" :items="delayModeList" style="max-width: 6em;" />
              </v-col>
              <v-col v-if="withDelay" cols="auto" >
                <base-text-field
                  v-model.number="model.action_json.delay_value"
                  :rules="delayValueRules"
                  :validation-placement="'top'"
                  style="max-width: 6em;"
                  placeholder="X"
                  validate-on-blur
                  type="number"
                  maxlength="5"
                  outlined
                />
              </v-col>
              <v-col v-if="withDelay" cols="auto">
                <v-select
                  v-model="model.action_json.delay_type"
                  style="max-width: 6em;"
                  class=""
                  :items="delayTypeList"
                  :rules="[(v) => !!v || 'Выберите']"
                />
              </v-col>
            </v-row>
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <BaseDialogFieldBlock
            title="Получатель рассылки"
            description="Укажите получателя рассылки."
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-radio-group
                      v-model="model.action_json.account_type"
                      class="mt-0"
                      hide-details
                    >
                      <v-radio
                        label="Текущий клиент (для которого отработала активность) "
                        :value="'current'"
                      />
                      <v-radio label="Реферер" :value="'referrer'" />
                      <v-radio
                        label="Другой клиент (выбрать из списка)"
                        :value="'manual'"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="model.action_json.account_type === 'manual'">
                  <v-col>
                    <account-select
                      v-model="model.action_json.account_id"
                      placeholder="Выберите клиента"
                      :rules="[(v) => !!v || 'Выберите клиента']"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import { get } from "lodash";
import { isFilled, isInteger, isPosNumber } from "@/utils/validate";

export default {
  components: {
    AccountSelect: () => import('@/components/ModelSelect/AccountSelect'),
  },
  model: {
    prop: "model",
    event: "update",
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  constants: {},
  data() {
    return {
      getNotifyPickListAction: false,
      delayModeList: [
        { text: "Сразу", value: false },
        { text: "Через:", value: true },
      ],
      delayTypeList: [
        { value: "SECOND", text: "Секунд" },
        { value: "MINUTE", text: "Минут" },
        { value: "HOUR", text: "Часов" },
        { value: "DAY", text: "Дней" },
      ],
      delayValueRules: [
        (v) => isFilled(v) || "Введите значение",
        (v) => isInteger(v) || "Должно быть целым числом",
        (v) => isPosNumber(v) || "Должно быть положительным",
        (v) => v <= 86400 || "Не более 86400",
      ],
    };
  },
  computed: {
    ...mapGetters("company/notifications", ["notificationPickList"]),
    withDelay: {
      get: function () {
        return !!this.model.action_json.delay_type;
      },
      set: function (v) {
        if (v) {
          this.model.action_json.delay_type = "SECOND";
          this.model.action_json.delay_value = 30;
        } else {
          this.model.action_json.delay_type = null;
          this.model.action_json.delay_value = null;
        }
      },
    },
  },
  mounted() {
    this.loadPickNotify();
    Vue.set(
      this.model.action_json,
      "account_type",
      this.model.action_json.account_type || "current"
    );
    Vue.set(
      this.model.action_json,
      "delay_type",
      this.model.action_json.delay_type || null
    );
  },
  methods: {
    ...mapActions({
      getNotifyPickList: "company/notifications/getPickList",
    }),

    async loadPickNotify() {
      try {
        this.getNotifyPickListAction = true;
        await this.getNotifyPickList(this.model.program_id);
      } catch (e) {
        console.error(e);
      } finally {
        this.getNotifyPickListAction = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-header {
  padding: 34px;
}
.dialog-body {
  padding: 34px;
  padding-right: 34px;
}
.cert-payment-select {
  margin-top: 14px;
  margin-bottom: 14px;
}
</style>
